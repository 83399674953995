var render = function () {
  var _vm$parseData$_vm$sel, _vm$selectedFilter, _vm$parseData$_vm$sel2, _vm$selectedFilter2, _vm$parseData$_vm$sel3, _vm$selectedFilter3, _vm$parseData$_vm$sel4, _vm$selectedFilter4, _vm$parseData$_vm$sel5, _vm$selectedFilter5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "gap": "16px",
      "flex-direction": "column"
    }
  }, [_c('ChipFilter', {
    attrs: {
      "options": _vm.optionFilters,
      "selected-filter": _vm.selectedFilter,
      "justify-content": "flex-start",
      "min-width": ['30px', '40px']
    },
    on: {
      "on-change-filter": _vm.onChangeFilter
    }
  }), _c('Info', {
    attrs: {
      "variant": "info",
      "text": "Pilih salah satu bahan makanan pada setiap golongan makanan. Contoh: Saat sarapan, pilih nasi sebagai makanan pokok, ikan sebagai lauk hewani, bayam sebagai sayur."
    }
  }), _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "20px",
      "font-weight": "600",
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.mealTileIdToLabel[(_vm$parseData$_vm$sel = _vm.parseData[(_vm$selectedFilter = _vm.selectedFilter) === null || _vm$selectedFilter === void 0 ? void 0 : _vm$selectedFilter.id]) === null || _vm$parseData$_vm$sel === void 0 ? void 0 : _vm$parseData$_vm$sel.mealTime]) + " - " + _vm._s((_vm$parseData$_vm$sel2 = _vm.parseData[(_vm$selectedFilter2 = _vm.selectedFilter) === null || _vm$selectedFilter2 === void 0 ? void 0 : _vm$selectedFilter2.id]) === null || _vm$parseData$_vm$sel2 === void 0 ? void 0 : _vm$parseData$_vm$sel2.caloriesAmount) + "kkal ")]), _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-clock.svg'),
      "height": "20px",
      "width": "20px",
      "fill": "#333333"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "color": "neutral.superDarkGray"
    }
  }, [_vm._v(" " + _vm._s((_vm$parseData$_vm$sel3 = _vm.parseData[(_vm$selectedFilter3 = _vm.selectedFilter) === null || _vm$selectedFilter3 === void 0 ? void 0 : _vm$selectedFilter3.id]) === null || _vm$parseData$_vm$sel3 === void 0 ? void 0 : _vm$parseData$_vm$sel3.startTime) + " - " + _vm._s((_vm$parseData$_vm$sel4 = _vm.parseData[(_vm$selectedFilter4 = _vm.selectedFilter) === null || _vm$selectedFilter4 === void 0 ? void 0 : _vm$selectedFilter4.id]) === null || _vm$parseData$_vm$sel4 === void 0 ? void 0 : _vm$parseData$_vm$sel4.endTime) + " ")])], 1)], 1), _c('base-divider', {
    attrs: {
      "color": "neutral.superDarkGray"
    }
  })], 1), _c('AccordionCustom', _vm._l((_vm$parseData$_vm$sel5 = _vm.parseData[(_vm$selectedFilter5 = _vm.selectedFilter) === null || _vm$selectedFilter5 === void 0 ? void 0 : _vm$selectedFilter5.id]) === null || _vm$parseData$_vm$sel5 === void 0 ? void 0 : _vm$parseData$_vm$sel5.mealComposition, function (item, idx) {
    var _item$mealItems, _item$additionalMealI;

    return _c('AccordionCustomItem', {
      key: idx,
      attrs: {
        "label": item === null || item === void 0 ? void 0 : item.foodGroup,
        "sub-label": "".concat(item === null || item === void 0 ? void 0 : item.portion, " porsi"),
        "is-disabled": false,
        "default-is-open": ""
      }
    }, [(item === null || item === void 0 ? void 0 : (_item$mealItems = item.mealItems) === null || _item$mealItems === void 0 ? void 0 : _item$mealItems.length) >= 1 || (item === null || item === void 0 ? void 0 : (_item$additionalMealI = item.additionalMealItems) === null || _item$additionalMealI === void 0 ? void 0 : _item$additionalMealI.length) >= 1 ? _c('c-box', [_c('c-text', {
      attrs: {
        "font-size": "16px",
        "color": "primary.400"
      }
    }, [_vm._v(" Pilihan bahan makanan ")]), _c('c-grid', {
      attrs: {
        "margin-top": "8px",
        "template-columns": "repeat(auto-fill, minmax(100px, 1fr))",
        "gap": "6"
      }
    }, [_vm._l(item === null || item === void 0 ? void 0 : item.mealItems, function (mealItem) {
      return _c('c-flex', {
        key: mealItem === null || mealItem === void 0 ? void 0 : mealItem.id,
        attrs: {
          "flex-direction": "column",
          "justify-content": "center",
          "align-items": "center",
          "w": "100%",
          "min-height": "10px",
          "margin-bottom": "auto"
        }
      }, [mealItem !== null && mealItem !== void 0 && mealItem.photoUrl ? _c('c-image', {
        attrs: {
          "src": mealItem === null || mealItem === void 0 ? void 0 : mealItem.photoUrl,
          "object-fit": "cover",
          "size": "72px",
          "_hover": {
            transition: 'transform 0.2s',
            transform: 'scale(1.25)'
          }
        }
      }) : _vm._e(), _c('c-text', {
        attrs: {
          "font-size": "14px",
          "text-align": "center"
        }
      }, [_vm._v(" " + _vm._s(mealItem === null || mealItem === void 0 ? void 0 : mealItem.foodName) + " ")]), _c('c-text', {
        attrs: {
          "color": "neutral.888888",
          "font-size": "14px",
          "text-align": "center"
        }
      }, [_vm._v(" " + _vm._s(_vm.onAdjustPortionMealItems(mealItem === null || mealItem === void 0 ? void 0 : mealItem.householdMeasurementPerPortion, item === null || item === void 0 ? void 0 : item.portionDecimal)) + " " + _vm._s(mealItem === null || mealItem === void 0 ? void 0 : mealItem.householdMeasurementUnitPerPortion) + " ")])], 1);
    }), _vm._l(item === null || item === void 0 ? void 0 : item.additionalMealItems, function (mealItem) {
      return _c('c-flex', {
        key: mealItem === null || mealItem === void 0 ? void 0 : mealItem.id,
        attrs: {
          "flex-direction": "column",
          "justify-content": "center",
          "align-items": "center",
          "w": "100%",
          "min-height": "10px"
        }
      }, [_c('c-box', {
        attrs: {
          "height": "72px",
          "width": "72px"
        }
      }, [_c('inline-svg', {
        attrs: {
          "src": require('@/assets/icons/icon-image-preview.svg'),
          "height": "72px",
          "width": "72px",
          "fill": "#008C81"
        }
      })], 1), _c('c-text', {
        attrs: {
          "font-size": "14px"
        }
      }, [_vm._v(" " + _vm._s(mealItem === null || mealItem === void 0 ? void 0 : mealItem.foodName) + " ")]), _c('c-text', {
        attrs: {
          "color": "neutral.888888",
          "font-size": "14px"
        }
      }, [_vm._v(" " + _vm._s(_vm.onAdjustPortionMealItems(mealItem === null || mealItem === void 0 ? void 0 : mealItem.householdMeasurement, item === null || item === void 0 ? void 0 : item.portionDecimal)) + " " + _vm._s(mealItem === null || mealItem === void 0 ? void 0 : mealItem.householdMeasurementUnit) + " ")])], 1);
    })], 2)], 1) : _c('NoData', {
      attrs: {
        "text": "Tidak ada data"
      }
    }), _c('BaseDivider', {
      attrs: {
        "color": "neutral.superDarkGray"
      }
    })], 1);
  }), 1), _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(2, 1fr)'],
      "gap": ['16px']
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "height": "60px",
      "border-radius": "60px",
      "font-size": "18px",
      "is-disabled": _vm.isFirstStep
    },
    on: {
      "click": _vm.handlePrevious
    }
  }, [_vm._v(" Sebelumnya ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "height": "60px",
      "border-radius": "60px",
      "font-size": "18px",
      "is-disabled": _vm.isLastStep
    },
    on: {
      "click": _vm.handleNext
    }
  }, [_vm._v(" Selanjutnya ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }