var render = function () {
  var _vm$data, _vm$data2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-flex', {
    attrs: {
      "gap": "16px",
      "flex-direction": "column"
    }
  }, [_c('CardInfoThingsThatNeed', {
    attrs: {
      "color": "warning",
      "label": "Yang perlu dibatasi",
      "items": ((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.limited) || ''
    }
  }), _c('CardInfoThingsThatNeed', {
    attrs: {
      "color": "danger",
      "label": "Yang harus dihindari",
      "items": ((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.avoided) || ''
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }