<template>
  <c-box>
    <c-flex
      gap="16px"
      flex-direction="column"
    >
      <CardInfoThingsThatNeed
        color="warning"
        label="Yang perlu dibatasi"
        :items="data?.limited || ''"
      />
      <CardInfoThingsThatNeed
        color="danger"
        label="Yang harus dihindari"
        :items="data?.avoided || ''"
      />
    </c-flex>
  </c-box>
</template>

<script>
import { CBox, CFlex } from '@chakra-ui/vue'
import CardInfoThingsThatNeed
  from '@/components/meal-plan/_widgets/card-info-things-that-need.vue'

export default {
  name: 'PreviewMealRecommendation',
  components: { CardInfoThingsThatNeed, CFlex, CBox },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
}
</script>
